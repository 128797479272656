import React from "react";
import useStateValue from "frontend/state/value";
import style from "./app-header.module.css";
import InviteButtonsTopBar from "frontend/modals/invite-buttons-topbar";
import { useBoardValue } from "frontend/state/board-state";
import { isUserPaying } from "frontend/utils/users-utils";
import useBoard from "frontend/hooks/use-board";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";
import { CheckoutSource } from "frontend/billing/checkout";
import { HiddenCursors } from "frontend/icons/hidden-cursors";
import SaveAsTemplate from "./save-as-template";

export default function AppHeaderBoard({
  title = null,
  renderUpgradeCTA,
  renderBoardUsersPresence,
  children,
  onInviteClick,
  onCreateTemplate,
  onCreateTemplateLink,
  setShowUpgradeModal,
  hideCursors,
}: {
  title: any;
  renderUpgradeCTA?: () => JSX.Element;
  renderBoardUsersPresence?: () => JSX.Element;
  children?: any;
  onInviteClick?: (title?: string) => void;
  onCreateTemplate: () => void;
  onCreateTemplateLink: () => void;
  setShowUpgradeModal: (reason: CheckoutSource | null) => void;
  hideCursors?: boolean;
}) {
  const [{ user }] = useStateValue();
  const [{ user: userValue, board }] = useBoardValue();
  const { hasAccessByTeamOrProject } = useBoard(board?.documentId!);

  const { canPerformAnyAction, canShareBoard } = useAbility();
  const shouldShowShareButton =
    user && board && (canShareBoard(user, board) || !canPerformAnyAction(user, board, InstanceType.Board));

  const shouldRenderInviteButton = userValue && !userValue.isAnonymous && hasAccessByTeamOrProject();
  const shouldRenderUpgradeCTA =
    userValue &&
    !userValue.isAnonymous &&
    !isUserPaying(user?.planInfo!) &&
    user?.planInfo?.source !== "touch" &&
    renderUpgradeCTA;

  const createTemplateEnable = useFeatureValue(consts.FEATURE_NAMES.CREATE_TEMPLATE_ENABLED) === "true";

  const renderShareButton = () => {
    if (shouldShowShareButton) {
      return <InviteButtonsTopBar onInviteClick={onInviteClick} />;
    }
    return null;
  };

  function onSaveTemplateToLibrary() {
    createTemplateEnable ? onCreateTemplate() : setShowUpgradeModal({ name: "create-template", value: "save-to-lib" });
  }

  function onSaveTemplateAsLink() {
    createTemplateEnable
      ? onCreateTemplateLink()
      : setShowUpgradeModal({ name: "create-template", value: "save-to-link" });
  }

  const renderSaveAsTemplateButton = () => {
    if (user?.account?.id === "5" && board) {
      return (
        <SaveAsTemplate
          onSaveTemplateToLibrary={onSaveTemplateToLibrary}
          onSaveTemplateAsLink={onSaveTemplateAsLink}
          createTemplateEnable={createTemplateEnable}
          boardDocumentId={board.documentId}
        />
      );
    } else {
      return null;
    }
  };

  const renderOldSaveAsTemplateButton = () => {
    if (user?.account?.id === "5") {
      return (
        <StyledButton
          title={"Save as template"}
          style={ButtonStyle.outline}
          onClick={() =>
            createTemplateEnable ? onCreateTemplate() : setShowUpgradeModal({ name: "create-template", value: "" })
          }
          icon={createTemplateEnable ? null : "/images/golden-crown.svg"}
          customStyle={{ height: 30 }}
        />
      );
    } else {
      return null;
    }
  };

  function renderVerticalDivider() {
    return user?.account?.id === "5" ? <div className={style.verticalDivider} /> : null;
  }

  return (
    <>
      {renderVerticalDivider()}
      {renderSaveAsTemplateButton()}
      {title && <div>{title}</div>}
      <div className={style.right}>
        <div className={style.content}>{children}</div>
        {hideCursors && <HiddenCursors />}
        {renderBoardUsersPresence && renderBoardUsersPresence()}
        <div className={style.contentButtons}>
          {renderShareButton()}
          {shouldRenderUpgradeCTA && renderUpgradeCTA()}
        </div>
      </div>
    </>
  );
}
