import { Crown } from "frontend/icons/crown";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import React, { useRef, useState } from "react";
import style from "./template-card.module.css";
import cn from "classnames";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas/canvas-template";
import {
  createMenuOption,
  OptionsContextMenu,
  OptionsContextMenuTheme,
} from "frontend/context-menu/optionsContextMenu";
import { PortalID } from "frontend/modal/usePortal";
import { useHover } from "frontend/ui-components/floaters/use-hover";

export enum TemplateCardStyle {
  small = "small",
  regular = "regular",
}

export function NewTemplateCard({
  template,
  previewAvailable,
  onClick,
  onPreview,
  cardStyle = TemplateCardStyle.regular,
  onEditTemplate,
  onDeleteTemplate,
}: {
  template: CanvasTemplateMinimal;
  previewAvailable: boolean;
  onClick: (template: CanvasTemplateMinimal) => void;
  onPreview: (template: CanvasTemplateMinimal) => void;
  cardStyle?: TemplateCardStyle;
  onEditTemplate: (template: CanvasTemplateMinimal) => void;
  onDeleteTemplate: (template: CanvasTemplateMinimal) => void;
}) {
  const isSmall = cardStyle === TemplateCardStyle.small;

  const name = template.name.charAt(0).toLocaleUpperCase() + template.name.slice(1);
  const [showMenu, setShowMenu] = useState(false);
  const menuButtonRef = useRef(null);
  const cardRef = useRef<any>(null);
  const hover = useHover(cardRef);

  const options = (template: CanvasTemplateMinimal) => {
    const options = [
      createMenuOption({
        title: "Edit template",
        iconPath: "/images/pencil-icon-new.svg",
        id: "edit-template",
        onClick: (e: any) => {
          e.stopPropagation();
          setShowMenu(false);
          onEditTemplate(template);
        },
      }),
      createMenuOption({
        title: "Delete template",
        iconPath: "/images/trash-icon.svg",
        id: "delete-template",
        onClick: (e: any) => {
          e.stopPropagation();
          setShowMenu(false);
          onDeleteTemplate(template);
        },
      }),
    ];
    return options;
  };

  function menuClicked(e: any) {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  function backgroundClicked() {
    setShowMenu(false);
  }

  return (
    <div
      className={cn(style.gridContainer, { [style.small]: isSmall })}
      data-testid={template.name}
      onClick={() => onClick(template)}
      ref={cardRef}
    >
      <div className={cn(style.templateImage, { [style.small]: isSmall })}>
        {template.thumbnailImageURL && <img src={template.thumbnailImageURL} />}
        {template.metadata?.mondaySolutionId && <img src="/images/monday-mini-logo.svg" className={style.mondayLogo} />}
        {previewAvailable && (
          <div className={style.buttons}>
            <StyledButton
              title="Use Template"
              size={ButtonSize.small}
              style={ButtonStyle.filled}
              customStyle={{ width: 120, height: 26 }}
            />
            <StyledButton
              title="Preview"
              size={ButtonSize.small}
              style={ButtonStyle.outline}
              onClick={(e: any) => {
                e.stopPropagation();
                onPreview(template);
              }}
              customStyle={{ width: 120, height: 26 }}
            />
          </div>
        )}
        {template.isOwner && (hover || showMenu) && (
          <div className={style.menuIcon} onClick={menuClicked} ref={menuButtonRef} data-testid="board_menu" />
        )}
      </div>
      <div className={style.titleContainer}>
        <div className={style.templateTitle}>{name}</div>
        {template.upgradeRequired && (
          <div className={style.proBadge}>
            <Crown size={14} />
          </div>
        )}
        {showMenu && (
          <OptionsContextMenu
            options={options(template)}
            attachToRef={menuButtonRef}
            onDismiss={backgroundClicked}
            theme={OptionsContextMenuTheme.light}
            portalID={PortalID.Modal}
            customStyle={{ paddingTop: 0 }}
          />
        )}
      </div>
    </div>
  );
}
